import { useState, useEffect } from 'react';
import Markdown from 'react-markdown';
import { ReactComponent as GitHubIcon } from "../../images/icon-github.svg";
import { ReactComponent as LinkedinIcon } from "../../images/icon-linkedin.svg";
import { ReactComponent as XIcon } from "../../images/icon-x.svg";
import '../../App.css';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent
} from '@mui/material';

const Footer = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [termsContent, setTermsContent] = useState('');

  useEffect(() => {
    fetch("./termsandpolicy.md")
      .then(response => response.text())
      .then(data => setTermsContent(data))
      .catch(error => console.error('Error loading terms:', error));
  }, []);

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <footer>
      <span>© 2024 - 2025 monju</span>
      <ul>
        <li>
          <a href="https://x.com/habatakurikei" target="_blank" rel="noopener noreferrer">
            <XIcon className="icon-x" />
          </a>
        </li>
        <li>
          <a href="https://github.com/Habatakurikei" target="_blank" rel="noopener noreferrer">
            <GitHubIcon className="icon-github" />
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/daisuke-yamaguchi-884929121/" target="_blank" rel="noopener noreferrer">
            <LinkedinIcon className="icon-linkedin" />
          </a>
        </li>
      </ul>
      <span id="terms-and-privacy" onClick={() => setOpenDialog(true)}>Terms &amp; Privacy</span>

      {/* Dialog for terms and policy */}
      <Dialog
        open={openDialog}
        onClose={handleClose}
      >
        <DialogContent sx={{ padding: '10px 5px 10px 30px', fontSize: '0.7rem' }}>
          <Markdown>{termsContent}</Markdown>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '24px' }}>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>

    </footer>
  );
}

export default Footer;
