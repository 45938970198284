import Markdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ClaudeIcon } from "../../images/anthropic.svg";
import { ReactComponent as DeepSeekIcon } from "../../images/deepseek.svg";
import { ReactComponent as GeminiIcon } from "../../images/google.svg";
import { ReactComponent as MistralIcon } from "../../images/mistral.svg";
import { ReactComponent as GPTIcon } from "../../images/openai.svg";
import { ReactComponent as XAIIcon } from "../../images/xai.svg";
import '../../App.css';

const MarkdownCanvas = ({ type, input, output }) => {

  const { t } = useTranslation();

  if (!output || typeof output !== 'object' || Object.keys(output).length === 0) return null;

  const icons = {
    anthropic: <ClaudeIcon style={{ height: "30px" }}/>,
    deepseek: <DeepSeekIcon style={{ height: "30px" }} />,
    google: <GeminiIcon style={{ height: "30px" }} />,
    mistral: <MistralIcon style={{ height: "30px" }} />,
    openai: <GPTIcon style={{ height: "30px" }} />,
    xai: <XAIIcon style={{ height: "30px" }} />,
  };

  const copyToClipboard = async (type, request, content) => {
    // Input request information
    var contentString = t('input.theme') + ": " + request.theme + "\n";
    contentString += t('input.num_ideas') + ": " + request.ideas + "\n";
    contentString += t('input.freedom') + ": " + request.freedom + "\n\n";
    contentString += t(`result_sidebar.${type}`) + "\n\n";

    // Add content
    contentString += Object.entries(content)
      .map(([key, value]) => `${key}:\n${value}\n`)
      .join('\n');

    // モダンなコピー方式はコメントアウト、理由はiPhone, iPadではエラーになるから
    // await global.navigator.clipboard.writeText(contentString)
    //   .then(() => alert(t('result_main.copy_clipboard_success')))
    //   .catch(err => console.error(t('result_main.copy_clipboard_error'), err));

    // フォールバックメカニズム（非推奨）
    const textArea = document.createElement('textarea');
    textArea.value = contentString;
    textArea.setAttribute('readonly', '');
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    const successful = document.execCommand('copy');
    const message = successful ? t('result_main.copy_clipboard_success') : t('result_main.copy_clipboard_error');
    alert(message);

    textArea.remove();
  };

  return (
    <>
      <div className="markdown-canvas">
        {Object.entries(output).map(([key, value]) => (
          <div key={key} className="markdown-column">
            {icons[key]}
            <Markdown className="markdown-content" components={{
              ul: ({node, ...props}) => <ul style={{paddingLeft: '20px'}} {...props} />,
              ol: ({node, ...props}) => <ol style={{paddingLeft: '20px'}} {...props} />
            }}>
              {value}
            </Markdown>
          </div>
        ))}
      </div>
      <div className="copy-clipboard-button-container">
        <button
          onClick={() => copyToClipboard(type, input, output)}
          className="copy-clipboard-button"
        >
          {t('result_main.copy_clipboard')}
        </button>
      </div>
    </>
  );
};

export default MarkdownCanvas;
